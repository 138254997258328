//Core Components
import React, {Component} from 'react';

//React Classes

//Assets

//CSS
import './index.css'

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ''
    }
  }

  enterListener=(e)=>{
    if(e.code === 'Enter'){
      // window.removeEventListener('keypress', this.enterListener);
      this.props.initializeWebsocketConnection(this.state.name)
    }
  };

  componentDidMount() {
    // window.addEventListener('keypress', this.enterListener)
  }

  render() {
    return (
      <div className='dialog'>
        <div className='dialogContainer'>
          <input autoFocus={true} placeholder='Your name' onChange={e=>this.setState({name: e.target.value})} value={this.state.name}/>
          <div className='dialogButtonContainer'>
           <div className='dialogButton' onClick={()=>this.props.initializeWebsocketConnection(this.state.name)}>
             <p>Continue</p>
           </div>
          </div>
        </div>

      </div>
    )
  }
}

export default Dialog